import React from 'react';

const ShippingDelivery = () => {
  return (
    <div className='content-padding terms'>
      <h1>Shipping and Delivery Policy</h1>
      <p><strong>Last updated on Aug 5, 2024</strong></p>
      
      <p>Shipping is not applicable for business.</p>
    </div>
  );
};

export default ShippingDelivery;
