import React, { useState } from 'react';
import { db, connectFirestoreEmulator } from './firebase-config'; // Import Firestore config
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './QuestionForm.css'; // Import your CSS file here

const QuestionForm = () => {
    const [question, setQuestion] = useState('');
    const [instagramId, setInstagramId] = useState('');
    const [message, setMessage] = useState('');
    const [popupText, setPopupText] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [docId, setDocId] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const doc = await addDoc(collection(db, 'questions'), { question, 
                timestamp: serverTimestamp(),
                status: 0,
                reel: '',
                username: instagramId,
                email: ''
             });
            setMessage('Your question has been submitted!');
            setPopupText(`आपका प्रश्न सफलतापूर्वक जमा हो चुका है और ये आपकी प्रश्न आईडी है ${doc.id} भविष्य में अपने प्रश्न का वर्तमान स्टेटस जानने के लिए आप ये आईडी हमे इंस्टाग्राम पर भेज सकते है। आईडी भेजने का तरीक़ा है "qid आपकी आईडी", उदाहरण के लिए अगर आपकी प्रश्न आईडी "sk1234" है तो "qid sk1234" भेजे।`);
            setShowPopup(true)
            setDocId(doc.id)
            setQuestion('');
        } catch (error) {
            console.error('Error adding document: ', error);
            setMessage('Failed to submit your question.');
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setMessage('')
        setInstagramId('')
        navigator.clipboard.writeText(docId)
            .then(() => {
                console.log('qid copied to clipboard:', docId);
            })
            .catch((err) => {
                console.error('Failed to copy qid: ', err);
            });
    };

    return (
        <div className='form-content' style={{ textAlign: 'center' }}>
            <h1>अपना प्रश्न पूँछे</h1>
            <div className="container">
                <form onSubmit={handleSubmit}>
                    <textarea
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        id="instagramId"
                        name="instagramId"
                        value={instagramId}
                        onChange={(e) => setInstagramId(e.target.value)}
                        placeholder="Instagram ID"
                        required
                    />
                    <button type="submit" className='button submit-button'>जमा करे!</button>
                </form>
                <div id="message">{message}</div>
            </div>

            <div className='footer'>
                <a href='/privacy-policy'>Privacy Policy</a>
                <a href='/refund'>Refund</a>
                <a href='/terms'>Terms & Conditions</a>
                <a href='/shipping'>Shipping</a>
                <a href='/contact'>Contact Us</a>
            </div>

            {/* Popup Structure */}
            {showPopup && (
                <div id="popup" className="popup">
                    <div className="popup-content">
                        <p id="popup-text">{popupText}</p>
                        <button onClick={handleClosePopup} className='button close-button'>आईडी कॉपी करे</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuestionForm;
