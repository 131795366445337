// src/components/QuestionsList.js
import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";

const QuestionsList = () => {
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const fetchQuestions = async () => {
            const querySnapshot = await getDocs(collection(db, "questions"));
            const questionsList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setQuestions(questionsList);
        };

        fetchQuestions();
    }, []);

    return (
        <div>
            <h1>Questions List</h1>
            <table>
                <thead>
                    <tr>
                        <th>QID</th>
                        <th>Timestamp</th>
                        <th>Question</th>
                    </tr>
                </thead>
                <tbody>
                    {questions.map((question) => (
                        <tr key={question.id}>
                            <td>{question.id}</td>
                            <td>{new Date(question.timestamp.seconds * 1000).toLocaleString()}</td>
                            <td>{question.question}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default QuestionsList;