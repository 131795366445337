import React from 'react';

const ContactUs = () => {
  return (
    <div className='content-padding terms'>
      <h1>Contact Us</h1>
      <p><strong>Last updated on Aug 5, 2024</strong></p>
      
      <p>You may contact us using the information below:</p>
      
      <p>
        E-Mail ID: <a href="mailto:yamraj@garudpuran.in">yamraj@garudpuran.in</a>
      </p>
      
      <p>
        Instagram <a href="https://www.instagram.com/garuda.puraan" target="_blank" rel="noopener noreferrer">Garuda Puraan</a>
      </p>

      <h2>
        Address:
      </h2>
      <p>
        Ram nagar, Shahdara, Delhi-110032
      </p>
    </div>
  );
};

export default ContactUs;
