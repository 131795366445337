// src/App.js
import React, { useEffect } from 'react';
import { auth, signInAnonymously } from './firebase-config';
import { onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA4 from 'react-ga4'
import QuestionForm from './QuestionForm';
import Login from './admin-panel/Login';
import PrivateRoute from './admin-panel/PrivateRoute';
import PrivacyPolicy from './terms/PrivacyPolicy';
import CancellationRefund from './terms/CancellationRefund';
import ContactUs from './terms/ContactUs';
import TermsConditions from './terms/TermsConditions';
import ShippingDelivery from './terms/ShippingDelivery';

function App() {
    ReactGA4.initialize('G-41PW1EP1R5')
    useEffect(() => {
        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                onAuthStateChanged(auth, (user) => {
                    if (user == null) {
                        signInAnonymously(auth)
                            .then(() => {
                                console.log('Signed in anonymously');
                            })
                            .catch((error) => {
                                console.error('Error signing in anonymously: ', error);
                            });
                    } else {
                        console.log('User is already signed in:', user);
                    }
                });
            })
            .catch((error) => {
                console.error('Error setting persistence: ', error);
            });
    }, []);

    return (
        <Router>
            <div className='App'>
                <Routes>
                    <Route path='/' element={<QuestionForm />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='refund' element={<CancellationRefund />} />
                    <Route path='/contact' element={<ContactUs />} />
                    <Route path='/terms' element={<TermsConditions />} />
                    <Route path='/shipping' element={<ShippingDelivery />} />
                    <Route path='/' element={<QuestionForm />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/admin' element={<PrivateRoute />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
