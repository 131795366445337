// src/firebase-config.js
import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator, signInAnonymously } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBLsk9dGR39gPOORd-N3XnrConv0SbGp9Q",
    authDomain: "garuda-590f0.firebaseapp.com",
    projectId: "garuda-590f0",
    storageBucket: "garuda-590f0.appspot.com",
    messagingSenderId: "363760833599",
    appId: "1:363760833599:web:93c5b68dbd382060c9d48a",
    measurementId: "G-MRJYE275TW"
  };

  const hostname = window.location.hostname;
  console.log(`hostname ${hostname}`)
  const app =
    hostname === "localhost"
      ? initializeApp({
          apiKey: "demo-key",
          authDomain: "demo-test",
          projectId: "demo-test",
          storageBucket: "default-bucket",
          appId: "demo-appId",
        })
      : initializeApp(firebaseConfig);

// const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

if (hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { db,connectFirestoreEmulator, auth, signInAnonymously };
